<template>
    <section class="rentalRate">
        <div class="top-bar bg-white" style="justify-content: space-between;align-items: center">
            <div>
                时<span style="padding: 10px"/>间 :<span style="padding: 10px"/>
                <el-select v-model="year" placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in 100" :key="index" :label="index+2000" :value="index+2000"/>
                </el-select>
<!--                <span style="padding: 10px">住房类型 :</span>-->
<!--                <el-select v-model="parent" placeholder="请选择" size="small">-->
<!--                    <el-option label="全部" :value="0"/>-->
<!--                    <el-option label="保障性租赁住房" :value="600210"/>-->
<!--                    <el-option label="长租公寓" :value="600220"/>-->
<!--                </el-select>-->
<!--                <span style="padding: 5px"/>-->
<!--                <el-select v-model="ziji"  placeholder="请选择" size="small">-->
<!--                    <el-option v-loading="loading" label="全部" :value="0"/>-->
<!--                    <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName" :value="item.code"/>-->
<!--                </el-select>-->
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleSearch">筛选</el-button>
                <!--   2024/02/05 张晓瑜注释 导出 跟查看已导出列表 --> 
                <!-- <el-button size="small">导出</el-button> -->
                <!-- <span style="padding-left: 30px;font-size: 18px;">
               <el-link type="primary" :underline="false" style="color:#2C8BF7!important;">查看已导出列表</el-link>
                 </span>  -->
                <span style="padding: 15px;font-size: 18px;">
                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;" @click="handleReset">重置筛选条件</el-link>
                </span>
            </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
            <div style="width: 49%;">
                <div>
                    <div class="title">房间出租率</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;">
                    <div id="roomECharts" style="height: 300px;"></div>
                </div>
            </div>
            <div style="width: 49%;">
                <div>
                    <div class="title">面积出租率</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;">
                    <div id="areaECharts" style="height: 300px;"></div>
                </div>
            </div>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentalRateTableRef" :dataRequest="getBillsLettingList" :columns="rentalRateTableColumn" :query="formSearch" :height="300">
                <template slot="empty">
                    <el-empty></el-empty>
                </template>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import * as echarts from "echarts";
    import {rentalRateTableColumn, eChartsOptions} from "@/views/bill-management/rent-withdrawal/data";
    import {getBillsLettingList,getBillsLettingLine,getBillsLettingNorentoutLine} from "@/api/rent-withdrawal";
    export default {
        name: "rentalRate",
        components: {},
        data() {
            return {
                formSearch: {},
                year: (new Date()).getFullYear(),
                roomECharts: null,
                areaECharts: null,
                eChartsOptions: eChartsOptions,
                rentalRateTableColumn,
                parent: 0,
                ziji: 0,
                houseTypes: [],
                loading: false
            };
        },
        methods: {
            getBillsLettingList(params){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                params.year = year;
                params.code = code;
                return getBillsLettingList(params);
            },
            eChartsInit(eChartsName,eChartsOptions) {
                let {...options} = eChartsOptions;
                this[eChartsName] = echarts.init(document.getElementById(eChartsName));
                this[eChartsName].setOption(options, true);
                window.addEventListener("resize", () => {
                    this[eChartsName].resize();
                });
            },
            handleSearch(){
                this.$refs["rentalRateTableRef"].pNumber = 1;
                this.$refs["rentalRateTableRef"].getTableData();
                this.getBillsLettingLine();
                this.getBillsLettingNorentoutLine();
            },
            handleReset() {
                this.formSearch = {page: 1, pageSize: 10};
                this.parent = 0;
                this.ziji = 0;
                this.year = (new Date()).getFullYear();
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            getBillsLettingLine(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillsLettingLine({year,code}).then(res=>{
                    let {xList, yList} = res.info;
                    this.setOption("roomECharts",xList, yList);
                }).catch(err=>{});
            },
            getBillsLettingNorentoutLine(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillsLettingNorentoutLine({year,code}).then(res=>{
                    let {xList, yList} = res.info;
                    this.setOption("areaECharts",xList, yList);
                }).catch(err=>{});
            },
            setOption(eChartsName,xList,yList){
                let {...eChartsOptions} = this.eChartsOptions;
                // 获取首个 ‘折线’ 数据，并将按照首个‘折线’数据的配置,装载‘折线’数据
                let legendData = {icon: 'roundRect', textStyle: {fontSize:15}};
                let legendDataArr = [];
                for (let item of yList) {
                    let {...data} = legendData;
                    data.name = item.name;
                    legendDataArr.push(data);
                }
                //填充‘折线’数据 主要是对样式的配置
                eChartsOptions.legend = {data: legendDataArr};
                //eCharts x轴配置
                eChartsOptions.xAxis.data = xList;
                //获取首个数据，并将按照首个数据的配置,装载数据
                let seriesData = {type: 'line',smooth:true};
                let seriesDataArr=[];
                for(let item of yList){
                    let {...data}=seriesData;
                    data.name = item.name;
                    data.data = item.data.map(a=>a/=100);
                    seriesDataArr.push(data);
                }
                //eCharts 填充数据
                eChartsOptions.series = seriesDataArr;
                this[eChartsName].setOption(eChartsOptions, true);
                //this.eChartsInit(eChartsName,eChartsOptions);
            }
        },
        async mounted() {
            this.eChartsInit("roomECharts", this.eChartsOptions);
            this.eChartsInit("areaECharts", this.eChartsOptions);
            this.getBillsLettingLine();
            this.getBillsLettingNorentoutLine();
        },
        beforeDestroy() {
            window.removeEventListener("resize", () => {
                this.roomECharts.resize();
                this.areaECharts.resize();
            });
        },
        watch: {
            async parent(value) {
                if (value !== 0) {
                    this.ziji = 0;
                    this.loading = true;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                    this.loading = false;
                } else {
                    this.ziji = 0;
                    this.houseTypes = [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rentalRate {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>